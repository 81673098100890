import React, { Fragment, useRef } from 'react'

import { Helmet } from 'react-helmet'

import Hero2 from '../components/hero2'
import Features3 from '../components/features3'
import Gallery14 from '../components/gallery14'
import Features10 from '../components/features10'
import Timeline111 from '../components/timeline111'
import Stats10 from '../components/stats10'
import Testimonial10 from '../components/testimonial10'
import Banner3 from '../components/banner3'
import BlogPostHeader12 from '../components/blog-post-header12'
import FAQ6 from '../components/faq6'
import ContactForm4 from '../components/contact-form4'
import Logos1 from '../components/logos1'
import Footer3 from '../components/footer3'
import Timeline1 from '../components/timeline1'
import './home.css'

const Home = (props) => {

  // Create a reference to the section you want to scroll to
  const contactUsRef = useRef(null);
  const LearnMoreRef = useRef(null);

  // Handle the button click
  const handleScrollToContactUs = () => {
    contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScrollToLearnMore = () => {
    LearnMoreRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Commit to Memory</title>
        <meta property="og:title" content="Commit to Memory" />
      </Helmet>
      <Hero2
        action1={
          <Fragment>
            <span className="home-text100">Donate Now</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text101" onClick={handleScrollToLearnMore}>Learn More</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text102">
              Support our mission to deliver an easy, fun, family friendly, and forever free Bible memorization application by
              making a donation today.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text103">
              Join us in sharing Commit to Memory
            </span>
          </Fragment>
        }
      ></Hero2>
      <Features3
        feature1Description={
          <Fragment>
            <span className="home-text104">
              Engage with church, family, and friends to keep each other accountable. Application built for all ages.
            </span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text105">
              Community Engaging
            </span>
          </Fragment>
        }
        sectionTitle={
          <Fragment>
            <span className="home-text106">Discover our Mission</span>
          </Fragment>
        }
        sectionDescription={
          <Fragment>
            <span className="home-text107">
            Commit to Memory is a Bible application dedicated for the purpose of memorizing verses.
            At the heart of our application is a commitment to excellence and accessibility. 
            We strive to create software that is not only visually stunning but also intuitive and user-friendly, 
            ensuring a seamless experience for everyone. By prioritizing clean design and straightforward functionality, 
            we aim to remove barriers and make powerful tools accessible to all. Best of all, our software is completely free.
            Check out the designs and images that come straight from our application to give you more insight into what we are building. 
            "Better to give than to receive" - Acts 20:35.
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text108">
              Daily Reviewing
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text109">
              Growth Inspiring
            </span>
          </Fragment>
        }
        secondaryAction={
          <Fragment>
            <span className="home-text110" onClick={handleScrollToLearnMore}>Learn More</span>
          </Fragment>
        }
        slogan={
          <Fragment>
            <span className="home-text111">Hide His Word in Your Heart - Deuteronomy 6:6</span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text112">
              Memorizing verses made easy with systems for reviewing references and verses in efficient methods.
            </span>
          </Fragment>
        }
        feature4Description={
          <Fragment>
            <span className="home-text113">
              Developed with rewards and rankings to encourage continued practice and daily activity.
            </span>
          </Fragment>
        }
        mainAction={
          <Fragment>
            <span className="home-text114" onClick={handleScrollToContactUs}>Contact Us</span>
          </Fragment>
        }
        feature4Title={
          <Fragment>
            <span className="home-text115">
              Streak Tracking
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text116">
              Track your personal growth and progress across time with built-in metrics and charts.
            </span>
          </Fragment>
        }
      ></Features3>
      {/* <Gallery14
        image1Description={
          <Fragment>
            <span className="home-text117">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </span>
          </Fragment>
        }
        image3Description={
          <Fragment>
            <span className="home-text118">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text119">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Suspendisse varius enim in eros elementum
              tristique. Duis cursus, mi quis viverra ornare, eros dolor
              interdum nulla.
            </span>
          </Fragment>
        }
        image2Description={
          <Fragment>
            <span className="home-text120">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </span>
          </Fragment>
        }
        image2Title={
          <Fragment>
            <span className="home-text121">Image 2</span>
          </Fragment>
        }
        image3Title={
          <Fragment>
            <span className="home-text122">Image 3</span>
          </Fragment>
        }
        image1Title={
          <Fragment>
            <span className="home-text123">Image 1</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text124">Discover the Key Features</span>
          </Fragment>
        }
      ></Gallery14> */}
      <Features10
        sectionDescription={
          <Fragment>
            <span className="home-text125">
              See a sneak peak into our epic Bible Memorization App and get inspired.
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text126">Login and Account Setup</span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text127">
              Where the journey begins, supports signing in with Google to create your account, also setup to allow non-emails.
            </span>
          </Fragment>
        }
        sectionTitle={
          <Fragment>
            <span className="home-text128">Gallery of the Design</span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text129">
              A peak into how the rest of the application will visually feel with The daily review page preview.
            </span>
          </Fragment>
        }
        mainAction={
          <Fragment>
            <span className="home-text130" onClick={handleScrollToContactUs}>Contact Us</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text131">Daily Review Page</span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text132">
              Check out our application logo that was uniquely created using AI image generation tools.
            </span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text133">Commit to Memory Logo</span>
          </Fragment>
        }
        slogan={
          <Fragment>
            <span className="home-text134">Psalm 34:8</span>
          </Fragment>
        }
        secondaryAction={
          <Fragment>
            <span className="home-text135" onClick={handleScrollToLearnMore}>Learn More</span>
          </Fragment>
        }
      ></Features10>
      {/* <Timeline111
        title1={
          <Fragment>
            <span className="home-text136">Stay up-to-date with our Timeline</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text137">Donate Now</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text138">Learn More</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text139">
              We believe in being fully transparent about the progress and development of Commit to Memory and the future plans.
            </span>
          </Fragment>
        }
        card1Date={
          <Fragment>
            <span className="home-text140">June 2022</span>
          </Fragment>
        }
        card2Date={
          <Fragment>
            <span className="home-text141">Jan. 2023</span>
          </Fragment>
        }
        card3Date={
          <Fragment>
            <span className="home-text142">Feb. 2024</span>
          </Fragment>
        }
        card4Date={
          <Fragment>
            <span className="home-text143">October 2024</span>
          </Fragment>
        }
        card1Content={
          <Fragment>
            <span className="home-text144">
              The plan for Commit to Memory started years before even with the use of other
              bible apps for memorizing verses.
            </span>
          </Fragment>
        }
        card1Heading={
          <Fragment>
            <span className="home-text145">Inital Planning</span>
          </Fragment>
        }
        card2Content={
          <Fragment>
            <span className="home-text146">
              The plan for Commit to Memory started years before even with the use of other
              bible apps for memorizing verses.
            </span>
          </Fragment>
        }
        card2Heading={
          <Fragment>
            <span className="home-text147">Release and Testing</span>
          </Fragment>
        }
        card3Content={
          <Fragment>
            <span className="home-text148">
              The plan for Commit to Memory started years before even with the use of other
              bible apps for memorizing verses.
            </span>
          </Fragment>
        }
        card3Heading={
          <Fragment>
            <span className="home-text149">Support Gathering</span>
          </Fragment>
        }
        card4Content={
          <Fragment>
            <span className="home-text150">
              The plan for Commit to Memory started years before even with the use of other
              bible apps for memorizing verses.
            </span>
          </Fragment>
        }
        card4Heading={
          <Fragment>
            <span className="home-text151">License Retrieving</span>
          </Fragment>
        }
      ></Timeline111> */}
      {/* <Timeline1
        card2Title={
          <Fragment>
            <span className="home-text213">Release and Testing</span>
          </Fragment>
        }
        date1={
          <Fragment>
            <span className="home-text214">Aug 2022</span>
          </Fragment>
        }
        card4Content={
          <Fragment>
            <span className="home-text215">
              Gathering and negiotating licensing has been a long process, and is still on-going.
            </span>
          </Fragment>
        }
        card4Action2={
          <Fragment>
            <span className="home-text216">Secondary action</span>
          </Fragment>
        }
        card4Action1={
          <Fragment>
            <span className="home-text217">Primary action</span>
          </Fragment>
        }
        card1Title={
          <Fragment>
            <span className="home-text218">Inital Planning</span>
          </Fragment>
        }
        card1Action2={
          <Fragment>
            <span className="home-text219">Secondary action</span>
          </Fragment>
        }
        card1Content={
          <Fragment>
            <span className="home-text220">
              It all began years ago when I was using other bible memorization apps.
              But after using them for more than 2 years daily, the tools became less and less user 
              friendly, and the idea came alive.
            </span>
          </Fragment>
        }
        date3={
          <Fragment>
            <span className="home-text221">Feb 2024</span>
          </Fragment>
        }
        card2Content={
          <Fragment>
            <span className="home-text222">
              After releasing, testing began
            </span>
          </Fragment>
        }
        card2Action1={
          <Fragment>
            <span className="home-text223">Primary action</span>
          </Fragment>
        }
        card3Title={
          <Fragment>
            <span className="home-text224">Support Gathering</span>
          </Fragment>
        }
        date4={
          <Fragment>
            <span className="home-text225">Oct 2024</span>
          </Fragment>
        }
        date2={
          <Fragment>
            <span className="home-text226">Jan 2023</span>
          </Fragment>
        }
        card3Action2={
          <Fragment>
            <span className="home-text227">Secondary action</span>
          </Fragment>
        }
        card3Action1={
          <Fragment>
            <span className="home-text228">Primary action</span>
          </Fragment>
        }
        card4Title={
          <Fragment>
            <span className="home-text229">License Retrieving</span>
          </Fragment>
        }
        card3Content={
          <Fragment>
            <span className="home-text230">
              Gathering support and marketting was a challenge at the start.
              Finding the right resources.
            </span>
          </Fragment>
        }
        card1Action1={
          <Fragment>
            <span className="home-text231">Primary action</span>
          </Fragment>
        }
        card2Action2={
          <Fragment>
            <span className="home-text232">Secondary action</span>
          </Fragment>
        }
      ></Timeline1> */}
      <Stats10
        stat1={
          <Fragment>
            <span className="home-text38">9%</span>
          </Fragment>
        }
        stat2={
          <Fragment>
            <span className="home-text39">71%</span>
          </Fragment>
        }
        stat3={
          <Fragment>
            <span className="home-text40">10</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text41">
              The statistics can be alarming, and it is our goal to make locating, reading, memorizing, and understanding scripture easier and more engaging for all ages!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text42">Bible usage across the World</span>
          </Fragment>
        }
        stat1Description={
          <Fragment>
            <span className="home-text43">
              Percentage of American adults reported reading the Bible daily in 2023.
            </span>
          </Fragment>
        }
        stat2Description={
          <Fragment>
            <span className="home-text44">Percentage of Americans expressing some degree of curiosity about its content of scripture.</span>
          </Fragment>
        }
        stat3Description={
          <Fragment>
            <span className="home-text45">Average amount of verses Christians have memorized. Many resources suggest memorizing as many as 150 key verses in the Bible.</span>
          </Fragment>
        }
        stat1ShortDescription={
          <Fragment>
            <span className="home-text46">Daily Reading</span>
          </Fragment>
        }
        stat2ShortDescription={
          <Fragment>
            <span className="home-text47">Curiosity</span>
          </Fragment>
        }
        stat3ShortDescription={
          <Fragment>
            <span className="home-text48">Verses Memorized</span>
          </Fragment>
        }
      ></Stats10>
      <Testimonial10
        review1={
          <Fragment>
            <span className="home-text163">
              This technology is doing something critically important. I’ve been a Christ follower for over 20 years and 
              I’ve been a leader in the science of behavior change for nearly as long, and the only way I’ve seen to 
              reliably take Bible verses into my daily life is to revisit them daily.
            </span>
          </Fragment>
        }
        author1Name={
          <Fragment>
            <span className="home-text166">Robert Feeney</span>
          </Fragment>
        }
        author1Position={
          <Fragment>
            <span className="home-text169">Founder of Ringorang</span>
          </Fragment>
        }
      ></Testimonial10>
      <Banner3
        heading1={
          <Fragment>
            <span className="home-text172">Commit to Memory</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text173">It is our goal to continually seek to spread the love of reading God's Word. Galatians 1:5-7</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text174">Donate Now</span>
          </Fragment>
        }
      ></Banner3>
      <BlogPostHeader12
        date={
          <Fragment>
            <span className="home-text175">23 March 2024</span>
          </Fragment>
        }
        readTime={
          <Fragment>
            <span className="home-text176">5 min read</span>
          </Fragment>
        }
        avatarName={
          <Fragment>
            <span className="home-text177">Bethany Weddle</span>
          </Fragment>
        }
        blogPostTitle={
          <Fragment>
            <span className="home-text178">Our Story</span>
          </Fragment>
        }
      ></BlogPostHeader12>
      <FAQ6
        faq0Question={
          <Fragment>
            <span className="home-text179">What all can I do with Commit to Memory?</span>
          </Fragment>
        }
        faq0Answer={
          <Fragment>
            <span className="home-text181">
                Commit to Memory is a bible memorization application that you can use to create your own list of verses to review daily. 
                There is no limit to the number of verses you can add and work to memorize. Functionality for reviewing verses by reference as 
                well as the verse text is available. You can create and join fellowships to encourage you friends and family to get involved and 
                keep each other accountable. More and more features are being developed to improve the experience and to bring a fresh mindset into 
                reading and memorizing scripture regularly.
            </span>
          </Fragment>
        }
        faq5Question={
          <Fragment>
            <span className="home-text179">Why should I donate to Commit to Memory?</span>
          </Fragment>
        }
        facqDetails={
          <Fragment>
            <span className="home-text180">
              Read a little more about us from our Frequently Asked Questions.
            </span>
          </Fragment>
        }
        faq3Answer={
          <Fragment>
            <span className="home-text181">
              Currently we are in the testing phrases before we want to release Commit to Memory to the world.
              If you are interested in becoming a beta tester or having early access to the application, please fill out the contact us form.
            </span>
          </Fragment>
        }
        faq4Answer={
          <Fragment>
            <span className="home-text182">
              As of now, the application is going through the development to release to the app store for Andriod and Apple devices. 
              The first version of the application has been developed as a Windows Desktop application. 
              It is our hope to release it by no later than December of 2025.
            </span>
          </Fragment>
        }
        faq6Question={
          <Fragment>
            <span className="home-text183">What do my donations go towards?</span>
          </Fragment>
        }
        faq6Answer={
          <Fragment>
            <span className="home-text184">
              Donations will go towards paying for the costs for hosting, licensing, and future developments.
              As donations increase, it will allow us to develop faster and better quality feature add-ons. 
              There's a lot that we have planned and wish to deliver, but everything takes time and money sadly.
            </span>
          </Fragment>
        }
        faq1Question={
          <Fragment>
            <span className="home-text185">What versions are supported in the Commit to Memory app?</span>
          </Fragment>
        }
        faqDetailsSubtitle={
          <Fragment>
            <span className="home-text186">
              <span>
                Fill out the Contact Us form! We would love to hear from you!
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        faqTitle={
          <Fragment>
            <span className="home-text189" ref={LearnMoreRef}>FAQs</span>
          </Fragment>
        }
        faq4Question={
          <Fragment>
            <span className="home-text190">When will Commit to Memory be available on the App store?</span>
          </Fragment>
        }
        faq5Answer={
          <Fragment>
            <span className="home-text191">
              As we strive to offer Commit to Memory freely, there are still costs for us. Upgrades and continued licensing for additional languages and bible versions 
              are supported by your donations. That to say, God loves a cheerful giver - 2 Corinthians 9:6-7. We want you to give as you feel lead and called to give, we aren't going to pull any legs here.
            </span>
          </Fragment>
        }
        faq3Question={
          <Fragment>
            <span className="home-text192">How can I start using Commit to Memory?</span>
          </Fragment>
        }
        faq2Answer={
          <Fragment>
            <span className="home-text193">
              Yes, there are no ads, no subscriptions, no one-time fees. We are dedicated to sharing God's Word freely. 
              As of now and the foreseeable future, it will continue to remain free even as development, updates, and improvements are released.
            </span>
          </Fragment>
        }
        faq2Question={
          <Fragment>
            <span className="home-text194">Is Commit to Memory actually free?</span>
          </Fragment>
        }
        faq1Answer={
          <Fragment>
            <span className="home-text195">
              The supported bible text versions are ever growing, since licensing must be granted to use the audio and text, it can take a little bit before new versions are included.
              We currently have licensing and support for the following versions: KJV, NKJV, NIV, NASB. Ones we are actively working towards including are ESV, NLT, and CSB.
              Currently the application only supports English versions, but it is our hope to work to include additional languages.
            </span>
          </Fragment>
        }
        contactAction={
          <Fragment>
            <span className="home-text196" onClick={handleScrollToContactUs}>Contact Us</span>
          </Fragment>
        }
        faqSubtitle={
          <Fragment>
            <span className="home-text197">Still have a question?</span>
          </Fragment>
        }
      ></FAQ6>
      <ContactForm4
        action={
          <Fragment>
            <span className="home-text198">Submit</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text199">
              <span>
                We&apos;d love to hear from you. Fill out the form below and
                we&apos;ll get back to you as soon as possible.
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text202">
              Want to give us a review, suggestion, or have a question, fill out the contact form!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span ref={contactUsRef} className="home-text203" onClick={handleScrollToContactUs}>Contact Us</span>
          </Fragment>
        }
      ></ContactForm4>
      <Logos1
        heading1={
          <Fragment>
            <span className="home-text204">
              The continually growing list of versions being developed in Commit to Memory
            </span>
          </Fragment>
        }
      ></Logos1>
      <Footer3
        link1={
          <Fragment>
            <span className="home-text205">Track Progress</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text206">Testimonials</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text207">App Development Phases</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text208">Join the Cause</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text209" onClick={handleScrollToContactUs}>Contact Us</span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="home-text210">Terms of Use</span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="home-text211">Cookies Policy</span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="home-text212">Privacy Policy</span>
          </Fragment>
        }
      ></Footer3>

    </div>
  )
}

export default Home
