import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './blog-post-header12.css'

const BlogPostHeader12 = (props) => {
  return (
    <div className="blog-post-header12-blog-post-header3 thq-section-padding">
      <div className="blog-post-header12-max-width thq-section-max-width">
        <div className="blog-post-header12-content1 thq-flex-column">
          <h2 className="thq-heading-2 features10-text11">Follow Us on our Social Media Platforms</h2>
        </div>
        <div className="blog-post-header12-content1 thq-flex-row">
          <div className="blog-post-header12-container">
            <video
              src={props.videoSrc}
              loop="true"
              muted="true"
              poster={props.videoPoster}
              autoPlay="true"
              className="blog-post-header12-video"
            ></video>
          </div>
          {/* <div className="blog-post-header12-section-title thq-flex-column">
            <h1 className="blog-post-header12-title thq-heading-1">
              {props.blogPostTitle ?? (
                <Fragment>
                  <span className="blog-post-header12-text4">
                    Latest Blog Posts
                  </span>
                </Fragment>
              )}
            </h1>
            <div className="blog-post-header12-content2">
              <div className="blog-post-header12-author">
                <span className="thq-body-small">By</span>
                <span className="thq-body-small">
                  {props.avatarName ?? (
                    <Fragment>
                      <span className="blog-post-header12-text7">
                        Full name
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <div className="blog-post-header12-time">
                <span className="thq-body-small">
                  {props.date ?? (
                    <Fragment>
                      <span className="blog-post-header12-text5">
                        23 March 2024
                      </span>
                    </Fragment>
                  )}
                </span>
                <span className="thq-body-small">•</span>
                <span className="thq-body-small">
                  {props.readTime ?? (
                    <Fragment>
                      <span className="blog-post-header12-text6">
                        5 min read
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div> */}
        </div>
        <div className="blog-post-header12-share-buttons">
          <a href="https://youtube.com/@commit.to.memory?si=EycnVaRXWW70b1Gr" target="_blank" rel="noopener noreferrer">
            <button className="thq-button-icon thq-button-filled">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
              </svg>
            </button>
          </a>
          <a href="https://www.instagram.com/commit.to.memory/profilecard/?igsh=dm4zamJienJuNHIy" target="_blank" rel="noopener noreferrer">
            <button className="thq-button-icon thq-button-filled">
              <svg
              viewBox="0 0 877.7142857142857 1024"
              className="thq-icon-small"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            </button>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61569467091117" target="_blank" rel="noopener noreferrer">
            <button className="thq-button-icon thq-button-filled">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="thq-icon-small"
              >
                <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
              </svg>
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

BlogPostHeader12.defaultProps = {
  blogPostTitle: undefined,
  date: undefined,
  readTime: undefined,
  avatarName: undefined,
  videoPoster: '/images/Backsplash.png',
  videoSrc: '/videos/Commit To Memory.mp4',
}

BlogPostHeader12.propTypes = {
  blogPostTitle: PropTypes.element,
  date: PropTypes.element,
  readTime: PropTypes.element,
  avatarName: PropTypes.element,
  videoPoster: PropTypes.string,
  videoSrc: PropTypes.string,
}

export default BlogPostHeader12
