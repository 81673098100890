import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './faq6.css'

const FAQ6 = (props) => {
  return (
    <div className="faq6faq7 thq-section-padding">
      <div className="thq-flex-column thq-section-max-width faq6-max-width">
        <div className="faq6-section-title thq-flex-column">
          <h2 className="faq6-text10 thq-heading-2">
            {props.faqTitle ?? (
              <Fragment>
                <span className="faq6-text24">FAQs</span>
              </Fragment>
            )}
          </h2>
          <p className="faq6-text11 thq-body-large">
            {props.facqDetails ?? (
              <Fragment>
                <span className="faq6-text15">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique.
                </span>
              </Fragment>
            )}
          </p>
        </div>
        <div className="thq-flex-column">
          <div className="faq6-list-item1">
            <p className="faq6-faq1-question thq-body-large">
              {props.faq0Question ?? (
                <Fragment>
                  <span className="faq6-text20">
                    Frequently Asked Questions
                  </span>
                </Fragment>
              )}
            </p>
            <span className="faq6-faq1-answer thq-body-small">
              {props.faq0Answer ?? (
                <Fragment>
                  <span className="faq6-text30">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="faq6-list-item1">
            <p className="faq6-faq1-question thq-body-large">
              {props.faq1Question ?? (
                <Fragment>
                  <span className="faq6-text20">
                    Frequently Asked Questions
                  </span>
                </Fragment>
              )}
            </p>
            <span className="faq6-faq1-answer thq-body-small">
              {props.faq1Answer ?? (
                <Fragment>
                  <span className="faq6-text30">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="faq6-list-item2">
            <p className="faq6-faq2-question thq-body-large">
              {props.faq2Question ?? (
                <Fragment>
                  <span className="faq6-text29">
                    Frequently Asked Questions
                  </span>
                </Fragment>
              )}
            </p>
            <span className="faq6-faq2-answer thq-body-small">
              {props.faq2Answer ?? (
                <Fragment>
                  <span className="faq6-text28">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="faq6-list-item3">
            <p className="faq6-faq3-question thq-body-large">
              {props.faq3Question ?? (
                <Fragment>
                  <span className="faq6-text27">
                    Frequently Asked Questions
                  </span>
                </Fragment>
              )}
            </p>
            <span className="faq6-faq3-answer thq-body-small">
              {props.faq3Answer ?? (
                <Fragment>
                  <span className="faq6-text16">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="faq6-list-item4">
            <p className="faq6-faq4-question thq-body-large">
              {props.faq4Question ?? (
                <Fragment>
                  <span className="faq6-text25">
                    Frequently Asked Questions
                  </span>
                </Fragment>
              )}
            </p>
            <span className="faq6-faq4-answer thq-body-small">
              {props.faq4Answer ?? (
                <Fragment>
                  <span className="faq6-text17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="faq6-list-item51">
            <p className="faq6-faq5-question1 thq-body-large">
              {props.faq5Question ?? (
                <Fragment>
                  <span className="faq6-text14">
                    Frequently Asked Questions
                  </span>
                </Fragment>
              )}
            </p>
            <span className="faq6-faq5-answer1 thq-body-small">
              {props.faq5Answer ?? (
                <Fragment>
                  <span className="faq6-text26">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="faq6-list-item52">
            <p className="faq6-faq5-question2 thq-body-large">
              {props.faq6Question ?? (
                <Fragment>
                  <span className="faq6-text18">
                    Frequently Asked Questions
                  </span>
                </Fragment>
              )}
            </p>
            <span className="faq6-faq5-answer2 thq-body-small">
              {props.faq6Answer ?? (
                <Fragment>
                  <span className="faq6-text19">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="thq-flex-column">
          <div className="faq6-content2">
            <h2 className="faq6-text12 thq-heading-2">
              {props.faqSubtitle ?? (
                <Fragment>
                  <span className="faq6-text32">Still have a question?</span>
                </Fragment>
              )}
            </h2>
            <p className="faq6-text13 thq-body-large">
              {props.faqDetailsSubtitle ?? (
                <Fragment>
                  <span className="faq6-text21">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </Fragment>
              )}
            </p>
          </div>
          <button className="thq-button-outline">
            <span className="thq-body-small">
              {props.contactAction ?? (
                <Fragment>
                  <span className="faq6-text31">Contact</span>
                </Fragment>
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

FAQ6.defaultProps = {
  faq5Question: undefined,
  facqDetails: undefined,
  faq3Answer: undefined,
  faq4Answer: undefined,
  faq6Question: undefined,
  faq6Answer: undefined,
  faq1Question: undefined,
  faqDetailsSubtitle: undefined,
  faqTitle: undefined,
  faq4Question: undefined,
  faq5Answer: undefined,
  faq3Question: undefined,
  faq2Answer: undefined,
  faq2Question: undefined,
  faq0Answer: undefined,
  faq0Question: undefined,
  faq1Answer: undefined,
  contactAction: undefined,
  faqSubtitle: undefined,
}

FAQ6.propTypes = {
  faq2Question: PropTypes.element,
  faq0Answer: PropTypes.element,
  faq5Question: PropTypes.element,
  facqDetails: PropTypes.element,
  faq3Answer: PropTypes.element,
  faq4Answer: PropTypes.element,
  faq6Question: PropTypes.element,
  faq6Answer: PropTypes.element,
  faq1Question: PropTypes.element,
  faqDetailsSubtitle: PropTypes.element,
  faqTitle: PropTypes.element,
  faq4Question: PropTypes.element,
  faq5Answer: PropTypes.element,
  faq3Question: PropTypes.element,
  faq2Answer: PropTypes.element,
  faq2Question: PropTypes.element,
  faq1Answer: PropTypes.element,
  contactAction: PropTypes.element,
  faqSubtitle: PropTypes.element,
}

export default FAQ6
